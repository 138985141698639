import type { App } from 'vue';
import type { ArcoIconOptions } from '../../_utils/types';
import _IconFolderAdd from './icon-folder-add.vue';

const IconFolderAdd = Object.assign(_IconFolderAdd, {
  install: (app: App, options?: ArcoIconOptions) => {
    const iconPrefix = options?.iconPrefix ?? '';
    app.component(iconPrefix + _IconFolderAdd.name, _IconFolderAdd);
  }
});

export default IconFolderAdd;
