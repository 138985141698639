import type { App } from 'vue';
import type { ArcoIconOptions } from '../../_utils/types';
import _IconMan from './icon-man.vue';

const IconMan = Object.assign(_IconMan, {
  install: (app: App, options?: ArcoIconOptions) => {
    const iconPrefix = options?.iconPrefix ?? '';
    app.component(iconPrefix + _IconMan.name, _IconMan);
  }
});

export default IconMan;
