import type { App } from 'vue';
import type { ArcoIconOptions } from '../../_utils/types';
import _IconMenu from './icon-menu.vue';

const IconMenu = Object.assign(_IconMenu, {
  install: (app: App, options?: ArcoIconOptions) => {
    const iconPrefix = options?.iconPrefix ?? '';
    app.component(iconPrefix + _IconMenu.name, _IconMenu);
  }
});

export default IconMenu;
