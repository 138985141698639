<template>
  <svg viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg" stroke="currentColor" :class="cls" :style="innerStyle" :stroke-width="strokeWidth" :stroke-linecap="strokeLinecap" :stroke-linejoin="strokeLinejoin" @click="onClick"><path d="M24 1C11.296 1 1 11.297 1 24s10.296 23 23 23c12.703 0 23-10.297 23-23S36.703 1 24 1Zm11.698 18.592c-.13 9.818-6.408 16.542-15.78 16.965-3.864.176-6.664-1.072-9.1-2.62 2.855.456 6.397-.686 8.292-2.307-2.8-.273-4.458-1.698-5.233-3.991.808.14 1.66.103 2.43-.06-2.527-.846-4.331-2.407-4.424-5.68.709.323 1.448.626 2.43.686-1.891-1.075-3.29-5.007-1.688-7.607 2.806 3.076 6.182 5.586 11.724 5.926-1.391-5.949 6.492-9.175 9.791-5.177 1.395-.27 2.53-.799 3.622-1.374-.45 1.381-1.315 2.347-2.37 3.119 1.158-.157 2.184-.44 3.06-.872-.544 1.128-1.732 2.14-2.754 2.992Z" fill="currentColor" stroke="none"></path></svg>
</template>

<script lang="ts">
import { defineComponent, computed, CSSProperties } from 'vue';
import { getPrefixCls } from '../../_utils/global-config';
import { isNumber } from '../../_utils/is';

export default defineComponent({
  name: 'IconTwitterCircleFill',
  props: {
    size: {
      type: [Number, String],
    },
    strokeWidth: {
      type: Number,
      default: 4
    },
    strokeLinecap: {
      type: String,
      default: 'butt',
      validator: (value: any) => {
        return ['butt', 'round', 'square'].includes(value);
      }
    },
    strokeLinejoin: {
      type: String,
      default: 'miter',
      validator: (value: any) => {
        return ['arcs', 'bevel', 'miter', 'miter-clip', 'round'].includes(value);
      }
    },
    rotate: Number,
    spin: Boolean
  },
  emits: {
    click: (ev: MouseEvent) => true,
  },
  setup(props, { emit }) {
    const prefixCls = getPrefixCls('icon');
    const cls = computed(() => [prefixCls, `${prefixCls}-twitter-circle-fill`, { [`${prefixCls}-spin`]: props.spin }]);
    const innerStyle = computed(() => {
      const styles: CSSProperties = {};
      if (props.size) {
        styles.fontSize = isNumber(props.size) ? `${props.size}px` : props.size;
      }
      if (props.rotate) {
        styles.transform = `rotate(${props.rotate}deg)`;
      }
      return styles;
    });
    const onClick = (ev: MouseEvent) => {
      emit('click', ev);
    };

    return {
      cls,
      innerStyle,
      onClick,
    };
  }
});
</script>
