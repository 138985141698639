import type { App } from 'vue';
import type { ArcoIconOptions } from '../../_utils/types';
import _IconHeart from './icon-heart.vue';

const IconHeart = Object.assign(_IconHeart, {
  install: (app: App, options?: ArcoIconOptions) => {
    const iconPrefix = options?.iconPrefix ?? '';
    app.component(iconPrefix + _IconHeart.name, _IconHeart);
  }
});

export default IconHeart;
