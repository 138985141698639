import type { App } from 'vue';
import type { ArcoIconOptions } from '../../_utils/types';
import _IconLayers from './icon-layers.vue';

const IconLayers = Object.assign(_IconLayers, {
  install: (app: App, options?: ArcoIconOptions) => {
    const iconPrefix = options?.iconPrefix ?? '';
    app.component(iconPrefix + _IconLayers.name, _IconLayers);
  }
});

export default IconLayers;
