import type { App } from 'vue';
import type { ArcoIconOptions } from '../../_utils/types';
import _IconEmail from './icon-email.vue';

const IconEmail = Object.assign(_IconEmail, {
  install: (app: App, options?: ArcoIconOptions) => {
    const iconPrefix = options?.iconPrefix ?? '';
    app.component(iconPrefix + _IconEmail.name, _IconEmail);
  }
});

export default IconEmail;
